import { Draggable } from "@hello-pangea/dnd";
import { Grid, Paper, Typography } from '@mui/material';

export default function DragOptionElement({element, index}) {
    return (
        <Draggable draggableId={element.id} index={index}>
            {(provided) => (
                <Grid key={index} item xs={12}>
                    <Paper variant="outlined" key={index} value={index+1} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <Typography variant="h6">
                            {element.option}
                        </Typography>
                    </Paper>
                </Grid>
            )}
        </Draggable>
    )
}
  
  
  