import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box } from '@mui/material';
const PtePattern = () => {


     return (
          <Box mt={4} sx={{ paddingX: { xs: 2, sm: 4 }, }}>
            <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem' } }}>
              PTE Exam Pattern
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                      The PTE (Pearson Test of English) is a computer-based test that measures English language skills for academic, immigration, and professional purposes.  The exam is divided into three main sections:
                    </Typography>
                    <List sx={{ pl: { xs: 1, sm: 2 } }}>
                              <ListItem>
                                <ListItemText
                                  primary="1. Speaking & Writing"
                                  secondary={
                                    "This part of the test is 54–67 minutes long and it contains seven different question types. It is the longest section of the test. You will be tested on your speaking and writing skills, using English."
                                  }
                                />
                              </ListItem>
                               <ListItem>
                                          <ListItemText
                                            primary="2. Reading"
                                            secondary={
                                              "This part of the test is 29–30 minutes long and it contains five different question types. Because PTE Academic is an integrated skills test, one item type ('Reading and Writing: Fill in the blanks') also assesses writing skills."
                                            }
                                          />
                               </ListItem>
                               <ListItem>
                               <ListItemText
                                           primary="3. Listening"
                                             secondary={
                                               "This part of the test is 30–43 minutes long and it contains eight different question types.The questions are based on audio or video clips, which begin to play automatically. You hear each audio or video clip once. You are allowed to take notes."
                                             }
                                           />
                               </ListItem>
                    </List>
                    <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
                              Total Duration
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                              The entire test takes approximately 2 hours and 30 minutes to complete.
                    </Typography>
            </Box>
            );
};
export default PtePattern;