import * as React from 'react'
import {Container, Card, CardContent, Typography} from '@mui/material';

function PrivacyPolicy() {
  return (
    <Container sx={{ py: 1}}>
        <Card >
            <CardContent>
            <Typography gutterBottom variant="h4" component="div" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
            Privacy Policy
              </Typography>
              <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
              1. Introduction
              </Typography>
              <Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
            Welcome to PrepFury (the "Website"). Your privacy is important to us. This Privacy Policy explains how we collect, use, and share your personal information when you visit our Website or use our services. By accessing or using PrepFury, you agree to the terms of this Privacy Policy.
            </Typography>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
            2. Information We Collect
              </Typography>
              <Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>

<b>2.1. Personal Information:</b> We may collect personal information that you voluntarily provide to us, including but not limited to:
<ul>
<li>Name</li>
<li>Email address</li>
<li>Contact details</li>
<li>Account credentials (username and password)</li>

<li>Payment information</li>

</ul>
<br/>
<b>2.2. Non-Personal Information:</b> We may automatically collect non-personal information when you visit our Website, including:
<ul>
<li>IP address</li>
<li>Browser type and version</li>
<li>Device information</li>
<li>Referring/exit pages</li>
<li>Date and time of visits</li>
<li>Operating system</li>
</ul>
<br/>
<b>2.3. Cookies and Tracking Technologies:</b> We use cookies and similar tracking technologies to enhance your experience on our Website. Cookies are small data files that are stored on your device. You can control the use of cookies through your browser settings.
</Typography>

<Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
3. How We Use Your Information
              </Typography>
              <Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>

We use the information we collect for various purposes, including:

<ul>
<li>To provide and maintain our services</li>
<li>To process transactions and manage your account</li>
<li>To improve our Website and services</li>
<li>To send newsletters, updates, and promotional materials</li>
<li>To respond to your inquiries and provide customer support</li>
<li>To analyze usage patterns and improve user experience</li>
</ul>


</Typography>

<Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
4. Sharing Your Information
              </Typography>

              <Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>

We do not sell or rent your personal information to third parties. We may share your information in the following situations:
<ul>
<li>With service providers who assist in our business operations</li>
<li>In connection with the sale, merger, or acquisition of our business</li>
<li>When required by law, regulation, or legal process</li>
<li>To protect our rights, privacy, safety, or property, or that of our users</li>
</ul>
</Typography>
<Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
5. Data Security
</Typography>
<Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>

We implement reasonable security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee absolute security.
</Typography>
<Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
6. Your Rights and Choices
</Typography>
<Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>

<b>6.1. Access and Correction:</b> You have the right to access, update, or correct your personal information. You can do so by logging into your account or contacting us directly.
<br/>
<b>6.2. Opt-Out:</b> You may opt out of receiving marketing communications from us by following the unsubscribe instructions provided in the emails.
<br/>
<b>6.3. Data Retention:</b> We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law.
</Typography>
<Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
7. Third-Party Links
</Typography>
<Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>

Our Website may contain links to third-party websites. We are not responsible for the privacy practices of these external sites. We encourage you to review their privacy policies.
</Typography>
<Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
8. Changes to This Privacy Policy
</Typography>
<Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>

We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically.
</Typography>
<Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
9. Contact Us
</Typography>
<Typography gutterBottom variant="body" component="div" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
If you have any questions or concerns about this Privacy Policy, please contact us at:<a href='mailto:cloudmedley@gmail.com'>PrepFury Email</a>
              </Typography>
               </CardContent>
          </Card>
          </Container>
  );
}
export default PrivacyPolicy;
