import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postRequest } from '../util/APIUtils';
import DoneIcon from '@mui/icons-material/Done';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { Box, Button, Container, Grid, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

const TestDetail = () => {  
  const location = useLocation()
  const { attemptedTestId,examName, quizTitle, date, totalScore } = location.state // Read values passed on state
  const [testData, setTestData] = useState([]);

  useEffect(() => {
    postRequest(`/api/atq/test-detail`, {attemptedTestId: attemptedTestId})
    .then(result => {
        setTestData(result);
        })
        .catch(error => {
        console.error('Error fetching data:', error);
        });
  }, []);

  
  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={1}>
       
        <Grid item xs={12}>
          <Card key={100} elevation={5} sx={{ backgroundColor: "#FF4d00" }}>
          <CardContent>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ justifyContent: "space-between", alignItems: "center" }}>
             <Typography variant="body1" color="white" sx={{ fontWeight: 'bold' }}><b>{examName}</b> - {quizTitle}</Typography>
          <Stack direction={{ xs: "row", sm: "row" }} spacing={2} sx={{ alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
             <Typography variant="body1" color="white">{examName === 'IELTS' ? "Band: " : "Score: "} {totalScore}</Typography>
             <Typography variant="body1" color="white">{date}</Typography>
          </Stack>
          </Stack>
          </CardContent>
          </Card>
       </Grid>
         {testData && testData.map((test, index) => (
            <Grid item xs={12} key={index}>
            <Card elevation={5} style={test.score > 0 ? { backgroundColor: "#e8f4ea" } : test.answer ? { backgroundColor: "#ffcccc" } : ''}>
            <CardContent>
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ justifyContent: "space-between",  alignItems: 'flex-start',width: '100%',}}>

           <Typography variant="body1" color="text.primary" sx={{ width: { xs: '100%', sm: '80%' },  marginBottom: { xs: 0, sm: 0 },}}>
                 {'Q' + (index + 1) + '. ' + test.question}
           </Typography>
           <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'right',width: { xs: '100%', sm: '20%' },marginTop: { xs: 0, sm: 0 },flexShrink: 0,display: { xs: 'block', sm: 'inline-block' }}}>
                 <b>Score:</b> {test.score}
           </Typography>
           </Stack>
       
        {test.option1 ? 
          (<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ justifyContent: 'flex-start',gap: { xs: 0, sm: 0 },width: '100%',}}>
    
          <Typography variant="body1" align="left" color="text.secondary" sx={{ width: '100%', display: 'inline-flex', alignItems: 'center' }} >
               {1 + '. ' + test.option1} 
               {test.correctAnswer == 1?<DoneIcon/>:test.answer == 1?<CancelTwoToneIcon/>:''}
          </Typography>
          <Typography variant="body1" align="left" color="text.secondary" sx={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
               {2 + '. ' + test.option2} 
               {test.correctAnswer == 2?<DoneIcon/>:test.answer == 2?<CancelTwoToneIcon/>:''}
          </Typography>
          <Typography variant="body1" align="left" color="text.secondary" sx={{ width: '100%', display: 'inline-flex', alignItems: 'center' }}>
              {3 + '. ' + test.option3} 
              {test.correctAnswer == 3?<DoneIcon/>:test.answer == 3?<CancelTwoToneIcon/>:''}
         </Typography>
         <Typography variant="body1" align="left" color="text.secondary" sx={{ width: '100%', display: 'inline-flex', alignItems: 'center' }}>
              {4 + '. ' + test.option4} 
              {test.correctAnswer == 4?<DoneIcon/>:test.answer == 4?<CancelTwoToneIcon/>:''}
         </Typography>
       {test.option5 && (
        <Typography variant="body1" align="left" color="text.secondary" sx={{ width: '100%', display: 'inline-flex', alignItems: 'center'}}>
              {5 + '. ' + test.option5} 
              {test.correctAnswer == 5?<DoneIcon/>:test.answer == 5?<CancelTwoToneIcon/>:''}
         </Typography>
        )}
        </Stack>
       ) : (
            <Stack direction="column" sx={{ justifyContent: "space-between" }}>
            <Typography variant="body1" align="left" color="text.secondary">
               <b>Your Answer:</b> {test.answer}
            </Typography>
            <Typography variant="body1" align="left" color="text.secondary">
               <b>Correct Answer:</b> {test.correctAnswer}
            </Typography>
            {test.mistakes && (
             <Typography variant="body1" align="left" color="text.secondary">
                <b>Mistakes:</b> {test.mistakes}
            </Typography>
             )}
           </Stack>
         )}       
      </CardContent>
    </Card>
  </Grid>
))}

    </Grid>
    </Container>
  );
};

export default TestDetail;