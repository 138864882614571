import React, { useState} from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CardMedia,Chip, Grid, Container, Card, CardContent, Typography, Button, RadioGroup, Radio, Stack, Checkbox} from '@mui/material';
import {FormControlLabel, FormControl,InputAdornment,Input, OutlinedInput, TextField, FormHelperText} from '@mui/material';
import { postRequest } from '../util/APIUtils';

function Checkout() {
    const {state} = useLocation();
    const [showPromoField, setShowPromoField] = useState(true)
    const [promoCode, setPromoCode] = useState("FREE100")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [discount, setDiscount] = useState(0)
    const [showDiscount, setShowDiscount] = useState(false)

    const [phoneError, setPhoneError] = useState(false)
    const [termsError, setTermsError] = useState(false)

    const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
    const [termsErrorMessage, setTermsErrorMessage] = useState("")
    const [isChecked, setIsChecked] = useState(false)

    
    const navigate = useNavigate();
    const { exam} = state; 
    const placeOrder = () => {
        if(phoneNumber == '')
        {
            setPhoneError(true)
            setPhoneErrorMessage("Phone Number is Mandatory.")
            return
        }
        if(isChecked == false)
        {
            setTermsError(true)
            setTermsErrorMessage("Please read and accept terms & conditions.")
            return
        }
        console.log("Posted data to phone pe", {"examId":exam.id, "mrp":exam.price, "discount":discount, "promoCode":promoCode, "mobileNumber":phoneNumber})
        postRequest('/api/payment/phonepe', {"examId":exam.id, "mrp":exam.mrp, "price":exam.price,"validUntil":exam.validUntil, "discount":discount, "promoCode":promoCode, "mobileNumber":phoneNumber, "validUntil": exam.valid})
        .then(response  => {
            if(JSON.stringify(response) === '{}'){
              navigate('/thankyou', {state:{exam: exam}})
            }else{
              window.open(response, "_self")
            }
          }
      );
        //console.log("Response received from post request:", response)
        //navigate('/thankyou', {state:{exam: exam}})
    };
    const handlePaymentMethod = e => {
        console.log('on change called', e.target.value)
        if(e.target.value == 'promo')
        {
            setShowPromoField(true)
        }
        else if (e.target.value == 'phonepe'){
          setDiscount(0)
          setShowDiscount(false)
          setShowPromoField(false)
        }
        else{
            setDiscount(0)
            setShowDiscount(false)
            setShowPromoField(false)
        }

      }
    const applyPromo = () =>{
        if(promoCode == 'DIWALI' || promoCode=='FREE100'){
            setDiscount(exam.price)
            setShowDiscount(true)
        }
        else{
            setDiscount(0)
            setShowDiscount(false)
        }
    };

    const handlePhone  = (e) =>{
        setPhoneNumber(e.target.value)
        setPhoneError(false)
        setPhoneErrorMessage("")
    };
    const handlePromo  = e =>{
        setPromoCode(e.target.value)
    };
    const handleTerms = () => {
        if(!isChecked){
            setTermsError(false)
            setTermsErrorMessage("")
        }
        setIsChecked(!isChecked)
    };
  
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
    <Grid container spacing={3}>
       <Grid item xs={12} md={5}>
                    <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
                        <CardMedia
                            component="img"
                            image={exam.url}
                            alt={exam.title}
                            sx={{ objectFit: 'cover', borderRadius: '8px', height: '100%' }}
                        />
                    </Card>
                </Grid>
      <Grid item xs={12} md={7}>
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>         
            <CardContent>
            <Typography variant="h5" sx={{ mb: 2 }} color="primary">
            Congratulations on choosing <b>{exam.title}</b> mock test series from PrepFury !!!
              </Typography>
            
              <Stack direction="row" spacing={2} alignItems="baseline" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" >
                Price
              </Typography>
              <Typography variant="h6" color="primary" sx={{ textAlign: 'right' }}><b>&#x20b9;{exam.price}</b></Typography>          
              </Stack>
              

              {showDiscount && (
                                <>
                                    <Stack direction="row" spacing={2} alignItems="baseline" mt={2} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body1">Discount </Typography>
                                        <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'right' }}>&#x20b9;{discount}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={2} alignItems="baseline" mt={1} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h6">Final Price </Typography>
                                        <Typography variant="h6" color="primary" sx={{ textAlign: 'right' }}><b>&#x20b9;{exam.price - discount}</b></Typography>
                                    </Stack>
                                </>
                            )}    
            <Stack direction="row" alignItems="center" spacing={1} mt={3}>
                     <Typography variant="h6" sx={{ flexShrink: 0 }}>Mobile :</Typography>
              <OutlinedInput id="outlined-adornment-phone" error={phoneError} size='small'
                    required startAdornment={<InputAdornment position="start">+91</InputAdornment>}
                    inputProps={{maxLength:10}} onChange={handlePhone} fullWidth/>
                    <FormHelperText error>{phoneErrorMessage}</FormHelperText>              
              </Stack>
              
               <Stack direction="column" spacing={1} mt={2}>
                  <Typography variant="h6">Payment Mode:</Typography>
              
              <RadioGroup
                aria-labelledby="payment-method-label"
                defaultValue="promo" name="payment-method"  onChange={handlePaymentMethod}>
                <Stack direction="column" spacing={2}>
                   <Grid container spacing={1} alignItems="center">
                      <Grid item xs={6} sm={6}>
                {/* <FormControlLabel value="phonepe" control={<Radio />} label="UPI/Debit Card/Credit Card/NetBanking" /> */}
                <FormControlLabel value="promo" control={<Radio size='small'/>} label="Promo Code" sx={{ display: 'flex', justifyContent: 'flex-start' }} />
                 </Grid>
                 <Grid item xs={6} sm={6}>
                {/* <FormControlLabel value="dbt" control={<Radio size='small'/>} label="Direct Bank Transfer" /> */}
                <FormControlLabel value="phonepe" control={<Radio size='small'/>} label="PhonePe" sx={{ display: 'flex', justifyContent: 'flex-start' }}/>
                </Grid>
                </Grid>
                </Stack>
            </RadioGroup>
            </Stack> 
            <Grid container spacing={1} mt={1}>
             <Grid item xs={12} sm={6}>
                  {showPromoField && (
                       <Stack direction="row" spacing={1} alignItems="center" width="100%">
                       <Input
                           placeholder="Enter Promo Code"
                           value={promoCode}
                           onChange={handlePromo}
                           size="small"
                           variant="standard"
                           fullWidth
                        />
                       <Button variant="outlined" size="small" onClick={applyPromo}>Apply</Button>
                       </Stack>
                   )}
              </Grid>
             </Grid>
                <FormControlLabel
                                error={termsError}
                                required
                                control={<Checkbox size="small" checked={isChecked} />}
                                label={<span>I have read and agree to the website <Link target="_blank" to={'/terms'}>terms and conditions</Link>.</span>}
                                onChange={handleTerms}
                                sx={{ mt: 3 }}
                            />
                            <FormHelperText error>{termsErrorMessage}</FormHelperText>

                            <Button
                                variant="contained"
                                sx={{ mt: 4, width: '100%', py: 1 }}
                                onClick={placeOrder}
                            >
                                Place Order
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>

                
            </Grid>
        </Container>
    );
}

            

export default Checkout;
