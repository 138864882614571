import React from 'react';
import { Container, Typography, Card, CardContent,CardMedia, Grid, Divider } from '@mui/material';


const UniversityList = (props) => {
  return (
    <>
      <Typography variant="h4" gutterBottom align="center">
        Top {props.country} Universities
      </Typography>
      <Grid container spacing={4}>
        {props.universities.map((university, index) => (
          <Grid item xs={12} key={index}>
            <Card>
                {/* <CardMedia
                component="img"
                height="200"
                image={university.image}
                alt={university.name}
              /> */}
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  {index + 1}. {university.name}
                </Typography>
                <Typography>{university.description}</Typography>
                <Typography>Notable alumni at {university.name} include <b>{university.notableAlumni.join(", ")}</b></Typography>
                <Typography>On an average {university.name} inducts <b>{university.enrollment.internationalStudents}</b> foreign students each year out of total <b>{university.enrollment.totalUndergraduates}</b> undergraduates. Indian students are around <b>{university.enrollment.indianStudents}</b>.</Typography>
                <Typography>Average Annual Tuition Fee: <b>{university.averageTuitionFee}</b></Typography>
                <Typography>Average Monthly Living Expenses: <b>{university.monthlyLivingExpenses}</b></Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Divider />
      </Grid>
      <Divider />
      </>
  );
};

export default UniversityList;