import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box } from '@mui/material';

const SscPattern = () => {
  return (
      <Box mt={4} sx={{ paddingX: { xs: 2, sm: 4 }, }}>
        <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem' } }}>
          SSC CGL Exam Pattern
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
          The SSC CGL (Staff Selection Commission Combined Graduate Level) exam is conducted for recruitment to various posts in ministries, departments, and organizations of the Government of India. The exam is divided into four tiers:
        </Typography>

        <List sx={{ pl: { xs: 1, sm: 2 } }}>
          <ListItem>
            <ListItemText
              primary="1. Tier I - Preliminary Exam"
              secondary={
                "This is a computer-based exam consisting of four sections: General Intelligence and Reasoning, General Awareness, Quantitative Aptitude, and English Comprehension. The duration of the exam is 60 minutes."
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="2. Tier II - Main Exam"
              secondary={
                "This is also a computer-based exam with four papers: Quantitative Abilities, English Language and Comprehension, Statistics, and General Studies (Finance and Economics). Each paper lasts for 2 hours."
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="3. Tier III - Descriptive Paper"
              secondary={
                "This is a pen-and-paper-based exam that tests the candidate's writing skills in English or Hindi. The paper includes essay writing, letter writing, and other descriptive tasks. The duration of the exam is 60 minutes."
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="4. Tier IV - Skill Test/Computer Proficiency Test"
              secondary={
                "This tier includes Data Entry Speed Test (DEST) and Computer Proficiency Test (CPT), depending on the specific post applied for. This tier is qualifying in nature."
              }
            />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
          Total Duration
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
          The overall duration varies depending on the number of papers attempted in Tier II and other qualifying requirements in Tier IV.
        </Typography>
      </Box>
  );
};

export default SscPattern;
