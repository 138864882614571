import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box } from '@mui/material';

const IeltsPattern = () => {
  return (
         <Box mt={4} sx={{ paddingX: { xs: 2, sm: 4 }, }}>
        <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem' } }}>
          IELTS General Exam Pattern
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
          The IELTS (International English Language Testing System) General Training exam assesses your English language proficiency and is typically taken for purposes such as work experience or immigration. The exam is divided into four main sections:
        </Typography>

        <List sx={{ pl: { xs: 1, sm: 2 } }}>
          <ListItem>
            <ListItemText
              primary="1. Listening"
              secondary={
                "The listening section lasts for 30 minutes. You will listen to four recordings of native English speakers and then write your answers to a series of questions."
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="2. Reading"
              secondary={
                "The reading section lasts for 60 minutes. It consists of three sections with a variety of texts, such as extracts from books, magazines, newspapers, advertisements, and other materials that are encountered in day-to-day life."
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="3. Writing"
              secondary={
                "The writing section is 60 minutes long. It includes two tasks: Task 1 involves writing a letter, and Task 2 is an essay based on a given topic."
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="4. Speaking"
              secondary={
                "The speaking section takes between 11 to 14 minutes. It consists of a face-to-face interview with an examiner, covering introductory questions, a topic-based monologue, and a discussion."
              }
            />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
          Total Duration
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
          The entire test takes approximately 2 hours and 45 minutes to complete.
        </Typography>
      </Box>
  );
};

export default IeltsPattern;
