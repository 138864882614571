import React from 'react'
import { Grid, Container, Card, CardContent, Typography, Button} from '@mui/material';
 import { Link, useLocation, useNavigate } from 'react-router-dom';


 function Thankyou() {
  const {state} = useLocation();

   const { exam} = state; 

return (
  <Container sx={{ py: 4 }}>
    <Grid container spacing={3} justifyContent="center">
    <Grid item xs={12} sm={10} md={8} lg={6}>
       <Card sx={{ border: "none", boxShadow: 3, p: 2 }}>           
            <CardContent>
              <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
              Congratulations on choosing <b>{exam.title}</b> mock test series from PrepFury !!!
              </Typography>
               <Typography variant="h5" component="div" sx={{ textAlign: 'center', mb: 3 }}>
                 Happy Learning.
               </Typography>
                  <Grid container justifyContent="center">
               <Button component={Link} to="/" variant="contained" color="primary"  sx={{
                    margin: 1,
                    fontSize: '1rem',
                    padding: '10px 20px',
                    '&:hover': {
                      backgroundColor: '#1976d2', // Keep the same hover color
                    },
                  }}>
                 Take Another Quiz
               </Button>
                </Grid>
               </CardContent>
             </Card>
           </Grid>
         </Grid>
       </Container>
   )
 }
 export default Thankyou;