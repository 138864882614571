import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CatFaqs from '../data/CatFaq.json';
import PteFaqs from '../data/PteFaq.json';
import IeltsFaqs from '../data/IeltsFaq.json';

const FaqTab = ({examName}) => {
    let faqs = []
    if(examName === "CAT"){
      faqs = CatFaqs
    }
    if(examName === "PTE"){
      faqs = PteFaqs
    }
    if(examName === "IELTS General" || examName === "IELTS Academic"){
      faqs = IeltsFaqs
    }
    return (<>
      <Typography variant="h5" align="center" gutterBottom>
        {examName} Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
    )       

};

export default FaqTab;
