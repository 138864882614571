import React, { useState, useEffect, createElement } from 'react'
import Quiz from '../components/Quiz'
import Topic from '../components/Topic'
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom'
import { getRequest } from '../util/APIUtils';
import PropTypes from 'prop-types';
import {Grid, Tabs, Tab, Box, Typography} from '@mui/material';
import DOMPurify from 'dompurify';
import PatternTab from './PatternTab';
import FaqTab from './FaqTab';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const QuizPage = (props) =>  {
  const location = useLocation();
  const [quizData, setQuizes] = useState(null);
  const [topics, setTopics] = useState(null);
  const exam_id = props.exam_id || location.state?.exam_id;
  const exam_name = props.exam_name || location.state?.exam_nam;
  const syllabus = props.syllabus || location.state?.syllabus || '';
  const practice = props.practice !== undefined ? props.practice : location.state?.practice || 0;
  const [value, setValue] = useState(practice === 1 ? 2 : 0);
  let faqs = [];
  useEffect(() => {
    if(practice == 1){
      setValue(2)
    }
    getRequest(`/public/exam/${exam_id}`)
      .then(result => {
        setQuizes(result)
      })
      .catch(error => {
        console.error('Error fetching quiz data:', error);
    })
    getRequest(`/public/topics/${exam_id}`)
    .then(result => {
      setTopics(result)
    })
    .catch(error => {
      console.error('Error fetching topics data:', error);
    })

  }, [])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <Container sx={{ py: 5 }}>
    <Box justifyContent="flex-end">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="exam options" 
        variant="standard" sx={{
              '& .MuiTabs-flexContainer': {
                justifyContent: 'flex-start', 
                gap: '20px',
                flexWrap: 'nowrap', 
              },'& .MuiTab-root': {
                minWidth: 'auto', 
                padding: '6px 8px', 
                fontSize: '0.8rem', 
              },'@media (max-width:600px)': {
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'space-between',
                },
                '& .MuiTab-root': {
                  minWidth: 60, 
                  fontSize: '0.7rem', 
                },},
              }}>
          <Tab label="Mock Tests" {...a11yProps(0)} />
          <Tab label="Pattern" {...a11yProps(1)} />
          <Tab label="Practice" {...a11yProps(2)} />
          <Tab label="FAQ" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={4}>
          {quizData &&
            quizData.map((item) => (
              <Quiz key={item.id} quiz={item} authenticated={props.authenticated}/>
            ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
          <PatternTab examName={exam_name}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <Grid container spacing={4}>
          {topics &&
            topics.map((item) => (
              <Topic key={item.topicId} topic={item} authenticated={props.authenticated}/>
            ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
          <FaqTab examName={exam_name}/>
      </CustomTabPanel>
    </Box>
    </Container>   
  )
}

export default QuizPage