
import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box } from '@mui/material';

const CatPattern = () => {
  return (
    <Box mt={4} sx={{ paddingX: { xs: 2, sm: 4 }, }}>
      <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem' } }}>
        CAT Exam Pattern
      </Typography>

      <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
        The CAT (Common Admission Test) is a national-level entrance exam for admission to MBA and other management programs. It's conducted annually by the Indian Institutes of Management (IIMs). The exam is divided into three main sections:
      </Typography>
      
      <List sx={{ pl: { xs: 1, sm: 2 } }}>
        <ListItem>
          <ListItemText
            primary="1. Verbal Ability & Reading Comprehension (VARC)"
            secondary={
              "The duration is 40 minutes, and it must be completed within the same timeframe. The reading comprehension part of the section dominates with approximately 67% weightage of the total score of this section, while the other 33% is reserved for verbal ability."
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. Data Interpretation & Logical Reasoning (DILR)"
            secondary={
              "The DILR section is the second section that follows the completion of the VARC section. It is the most undefined section compared to the other sections, and candidates often struggle with it. The two subsections of the DILR section in the syllabus are: Logical Reasoning and Data Interpretation."
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. Quantitative Aptitude (QA)"
            secondary={
              "This part of the test is 40 minutes long and the entire CAT QA syllabus is divided into five main spheres, namely Algebra, Arithmetic, Geometry, Modern Math, and Number Systems."
            }
          />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
        Total Duration
      </Typography>
      
      <Typography variant="body1" paragraph sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
        The entire test takes approximately 2 hours and 30 minutes to complete.
      </Typography>
    </Box>
  );
};

export default CatPattern;
