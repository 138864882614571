import DragOptionElement from "./DragOptionElement";
import { Droppable } from "@hello-pangea/dnd";
import { Grid} from '@mui/material';

export default function DropPanel({options}) {

  
    return (
            <Droppable droppableId="droppable-1">
            {provided => (
            <Grid ref={provided.innerRef} container justifyContent="center" sx={{margin: "2px"}} spacing={1}>
                {options.map((option, index) => (
                    <DragOptionElement key={index} element={option} index={index} />
                ))}
            {provided.placeholder}       
            </Grid>
        )
        }
            </Droppable>
    )
  }
  
  
  