import "../util/tex-mml-chtml.js";
import React, { Component } from 'react';
import {
  Route,
  Routes
} from 'react-router-dom';
import AppHeader from '../common/AppHeader';
import AppFooter from '../common/AppFooter';
import Home from '../pages/Home';
import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import Profile from '../user/profile/Profile';
import OAuth2RedirectHandler from '../user/oauth2/OAuth2RedirectHandler';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import { getCurrentUser } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';
import PrivateRoute from '../common/PrivateRoute';
import QuizPage from '../pages/QuizPage';
import Thankyou from '../pages/Thankyou';
import QuestionPage from '../pages/QuestionPage';
import ResultPage from '../pages/ResultPage';
import MyTests from '../pages/MyTests';
import TestDetail from "../pages/TestDetail.js"
import PrivacyPolicy from "../pages/PrivacyPolicy.js"
import RefundPolicy from "../pages/RefundPolicy.js"
import TermsConditions from "../pages/TermsConditions.js"
import Checkout from "../pages/Checkout.js"
import Confirmation from "../pages/Confirmation.js";
import QuestionPageForTopic from "../pages/QuestionPageForTopic.js";
import UniversityList from "../pages/UniversityList.js";
import USAUninversities from "../data/USUniversities.json";
import UKUninversities from "../data/UKUniversities.json";
import AusUninversities from "../data/AusUniversities.json";
import CanadaUninversities from "../data/CanadaUniversities.json";
import NZUninversities from "../data/NZUniversities.json";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      currentUser: null,
      loading: true,
      examIds: null
    }

    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLogin = this.handleLogin.bind(this)
  }

  handleLogin() {
    this.loadCurrentlyLoggedInUser()
  }
  loadCurrentlyLoggedInUser() {
    getCurrentUser()
    .then(response => {
      this.setState({
        currentUser: response,
        authenticated: true,
        loading: false,
        examIds: response.examIds
      });
    }).catch(error => {
      this.setState({
        loading: false
      });  
    });    
  }

  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    this.setState({
      authenticated: false,
      currentUser: null,
      examIds: null
    });
    //Alert.success("You're safely logged out!");
  }

  componentDidMount() {
    this.loadCurrentlyLoggedInUser();
  }

  render() {
    if(this.state.loading) {
      return <LoadingIndicator />
    }

    return (
      <div className="app">
        <div className="app-top-box">
          <AppHeader authenticated={this.state.authenticated} onLogout={this.handleLogout} currentUser={this.state.currentUser}/>
        </div>
        <div className="app-body" style={{ minHeight: '100vh'}}>
          <Routes>
            <Route exact path="/" element={<Home allowedExams={this.state.examIds} authenticated={this.state.authenticated}/>}></Route>           
            {/* <PrivateRoute path="/profile" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={Profile}></PrivateRoute> */}
              <Route path="/ielts-general-practice" element={<QuizPage exam_id="4" exam_name="IELTS General" syllabus="" practice={1} />}/>
            <Route path='/profile' element={<PrivateRoute component={Profile} authenticated={this.state.authenticated}/>}>
              <Route path='/profile' element={<Profile currentUser={this.state.currentUser}/>}/>
            </Route>
            <Route path="/login"
              element={<Login authenticated={this.state.authenticated} change = {this.handleLogin}/>}></Route>
            {/* <Route path="/login"
              Component={(props) => <Login authenticated={this.state.authenticated} {...props} />}></Route> */}
            <Route path="/signup"
              Component={(props) => <Signup authenticated={this.state.authenticated} {...props} />}></Route>
            {/* <Route path="/oauth2/redirect" Component={OAuth2RedirectHandler}></Route>   */}
            <Route path="/oauth2/redirect"
              Component={(props) => <OAuth2RedirectHandler {...props} change = {this.handleLogin}/>}></Route>
            
            <Route path='/quiz' Component={(props) => <QuizPage authenticated={this.state.authenticated} {...props} />}></Route>
            <Route path="/ielts-general-practice" element={<QuizPage exam_id="4" exam_name="IELTS General" syllabus="" practice={1} />}/>
            <Route path="/ielts-academic-practice" element={<QuizPage exam_id="5" exam_name="IELTS Academic" syllabus="" practice={1} />}/>
            <Route path="/pte-academic-practice" element={<QuizPage exam_id="6" exam_name="PTE Academic" syllabus="" practice={1} />}/>
            <Route path="/cat-practice" element={<QuizPage exam_id="1" exam_name="CAT" syllabus="" practice={1} />}/>
            <Route path="/ielts-general-mock" element={<QuizPage exam_id="4" exam_name="IELTS General" syllabus=""  />}/>
            <Route path="/ielts-academic-mock" element={<QuizPage exam_id="5" exam_name="IELTS Academic" syllabus=""  />}/>
            <Route path="/pte-academic-mock" element={<QuizPage exam_id="6" exam_name="PTE Academic" syllabus=""  />}/>
            <Route path="/cat-mock" element={<QuizPage exam_id="1" exam_name="CAT" syllabus=""  />}/>
            <Route path='/questions' Component={QuestionPage} />
            <Route path='/practice-questions' Component={QuestionPageForTopic} />
            <Route path='/thankyou' Component={Thankyou} />
            <Route path='/result' Component={ResultPage} />
            <Route path="/my-tests" element={<MyTests currentUser={this.state.currentUser}/>} />
            <Route path="/detail"  Component={TestDetail} />
            <Route path="/privacy-policy"  Component={PrivacyPolicy} />
            <Route path="/terms"  Component={TermsConditions} />
            <Route path="/refunds"  Component={RefundPolicy} />
            <Route path="/checkout"  Component={Checkout} />
            <Route path="/confirm"  Component={Confirmation} />
            <Route path="/thankyou"  Component={Thankyou} />
            <Route path="/top-usa-univ"  Component={(props) => <UniversityList universities={USAUninversities} country={"USA"}/>} />
            <Route path="/top-uk-univ"  Component={(props) => <UniversityList universities={UKUninversities} country={"UK"}/>} />
            <Route path="/top-aus-univ"  Component={(props) => <UniversityList universities={AusUninversities} country={"Australia"}/>} />
            <Route path="/top-canada-univ"  Component={(props) => <UniversityList universities={CanadaUninversities} country={"Canada"}/>} />
            <Route path="/top-nz-univ"  Component={(props) => <UniversityList universities={NZUninversities} country={"New Zealand"}/>} />
            <Route component={NotFound}></Route>
          </Routes>
        </div>
        <div>
          <AppFooter/>
        </div>
        {/* <Alert stack={{limit: 3}} 
          timeout = {3000}
          position='top-right' effect='slide' offset={65} /> */}
      </div>
    );
  }
}

export default App;
