import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Stack, Grid, Container, Card, CardContent, Typography, Button} from '@mui/material';

function ResultPage() {
    const {state} = useLocation();
    const navigate = useNavigate();
    const { correctQuestions, totalQuestions, score, quizId, attemptedTestId, examName, quizTitle} = state; // Read values passed on state
    const handleDetail = () => {
      navigate('/detail', { state: { attemptedTestId: attemptedTestId, examName: examName, quizTitle: quizTitle, date: (new Date()).toLocaleDateString(), totalScore: score} })
    };
  
  return (
    <Container sx={{ py: 1 }}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card align="center" sx={{p:2}}>
            <CardContent>
            <Typography gutterBottom variant="h6" component="div"
            sx={{
            fontWeight:600
            }}
            >
            Congratulations on completing <b>{examName}</b>-{quizTitle} !
              </Typography>
              <Typography gutterBottom variant="h6" component="div"
              >
              Correct Questions: {correctQuestions}
              </Typography>
              <Typography gutterBottom variant="h6" component="div"
              >
              Total Questions: {totalQuestions}
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
              Score: {score}
              </Typography>
              <Stack direction={{xs:'column',sm:'row'}} spacing={2} sx={{mt:2,justifyContent:"center"}}>

              <Button variant="contained" onClick={() => handleDetail()} sx={{ 
                                        width: { xs: '100%', sm: 'auto' }, 
                                        margin: { xs: '0.5rem 0', sm: '5px' } 
                                    }}>
                View Details
              </Button>
              <Button component={Link} to="/" variant="contained" color="primary" sx={{ 
                                        width: { xs: '100%', sm: 'auto' }, 
                                        margin: { xs: '0.5rem 0', sm: '5px' } 
                                    }}>
                Take Another Quiz
              </Button>              
              </Stack>
            </CardContent>
          </Card>
          </Grid>
          </Grid>
          </Container>
  )
}

export default ResultPage
