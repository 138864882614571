import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import IeltsPattern from './patterns/IeltsPattern';
import SscPattern from './patterns/SscPattern';
import PtePattern from './patterns/PtePattern';
import CatPattern from './patterns/CatPattern';

const PatternTab = ({examName}) => {
    if(examName == 'IELTS General'){
        return <IeltsPattern/>
    }else if(examName == 'SSCCGL'){
        return <SscPattern/>
    }
    else if(examName == 'PTE'){
        return <PtePattern/>
    }
    else if(examName == 'CAT'){
        return <CatPattern/>
    }
    else if(examName == 'IELTS Academic'){
        return <IeltsPattern/>
    }
            

};

export default PatternTab;
